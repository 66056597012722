import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styles from "./brands-grid.module.scss"

export default class BrandsGrid extends React.Component {
  buildGrid(brands) {
    const divs = []
    brands.forEach((brand, index) => {
      const { logo } = { ...brand.frontmatter }
      const { slug } = { ...brand.fields }
      if (logo && logo.childImageSharp && logo.childImageSharp.fluid) {
        divs.push(
          <Link to={slug} key={index}>
            <div key={index} className={styles.item}>
              <Img key={index} fluid={logo.childImageSharp.fluid} />
            </div>
          </Link>
        )
      }
    })
    return divs
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            brands: allMarkdownRemark(
              filter: { frontmatter: { templateKey: { eq: "brand" } } }
            ) {
              nodes {
                frontmatter {
                  logo {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                fields {
                  slug
                }
              }
            }
            brandspage: markdownRemark(
              frontmatter: { templateKey: { eq: "brands" } }
            ) {
              fields {
                slug
              }
            }
          }
        `}
        render={query => {
          return (
            <div className={styles.brandsGrid + " padding-s"}>
              {this.buildGrid(query.brands.nodes)}
            </div>
          )
        }}
      />
    )
  }
}
// TODO brandspage not used?
