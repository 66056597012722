// import "../styles/index.scss"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import BrandsGrid from "../components/brands-grid"
import Footer from "../components/footer"
import GridItemWithHeading from "../components/grid-item-with-heading"
import Header from "../components/header"
import SEO from "../components/seo"

export default class BrandsPageTemplate extends React.Component {
  render() {
    const { image1, image2, image3, title, description, metaImage } = {
      ...this.props.data.brands.frontmatter,
    }
    return (
      <div>
        <SEO
          title={title}
          description={description}
          metaImage={metaImage}
          pathname={this.props.location.pathname}
        />
        <Header></Header>
        <main className="grid grid-col-1-3-2">
          <div className="grid-item-1x2">
            <GridItemWithHeading
              image={image1.childImageSharp.fluid}
              heading={title}
            ></GridItemWithHeading>
          </div>
          <div className="grid-item-1x1">
            <Img fluid={image2.childImageSharp.fluid} />
          </div>
          <div className="grid-item-1x1">
            <Img fluid={image3.childImageSharp.fluid} />
          </div>
          <div className="grid-item-2x1">
            <BrandsGrid></BrandsGrid>
          </div>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

// TODO Seperate graphql file? Sharing images on other templates?
export const query = graphql`
  query {
    brands: markdownRemark(frontmatter: { templateKey: { eq: "brands" } }) {
      frontmatter {
        image1 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
        metaImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
