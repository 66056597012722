import Img from "gatsby-image"
import React from "react"
import styles from "./grid-item-with-heading.module.scss"

export default ({ image, heading }) => (
  <div className={styles.item}>
    <h1>{heading}</h1>
    <Img fluid={image} />
  </div>
)
